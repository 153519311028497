export const AgileHandoverServer ="https://cloud.agilehandover.com";// "http://192.168.1.185" ; //"https://cloud.agilehandover.com";// 
//local
/*const AgileHandoverSeedConfig = {
    userId:"uE79KdPaptEgrcafe",
    userSecret:"Pu2Z5pPUurtxZ5QAxiVl_wPJnleS8IC9VIcX3-OrpgT",
}*/

//cloud
const AgileHandoverSeedConfig = {
    userId:"7pkJMDxdpRCuh9m9D",
    userSecret:"qk64LN2YTPEyJmnekrgUrfiG2YEJVcWozTzII2BjWV4",
}
var AgileHandoverConfig = {
    userId:"57ms47sWsdZBmB9xE",
    userSecret:"xtriR-JqIdoUbw0yyxMpDFr4W0pFUXBm8PA4kb7eC95",
}
export enum ProcessType {
    Asset =0,
    PID = 1
}
export interface ProcessIModelPilelineProps {
    imodels:string[];
    classes:any; //can be an array of classes or schema
    options?: any;
    associations?:any
  }
/*export interface ProcessIModelProps {
    contextId: string;
    packageId: string;
    
    pipeline : any;
  }*/

export class AgileHandoverRepository {
    public static ServerAvailable=true;
    private url:string;
    constructor(url:string) {
        this.url = url;
    }

    public async login(itwinId :string,  email:string, password:string) {
        let base =  this.url +"/api/login";
       
        try {
            let response = await fetch(base, {
                method: 'POST',
                body : JSON.stringify({user:email, password:password}),
                headers: {
                    'Content-Type': 'application/json'
                   
                    }
            });
            let resp = await response.json();
            if (resp.data) {
                AgileHandoverConfig.userId = resp.data.userId;
                AgileHandoverConfig.userSecret = resp.data.authToken;
                if (AgileHandoverConfig && AgileHandoverConfig.userId) {
                    await this.setLinkedAgileUser(itwinId, resp.data);
                }
            } else {
                AgileHandoverConfig.userId = "";
                AgileHandoverConfig.userSecret ="";
            }
            
            return AgileHandoverConfig;
        } catch (e) {
            AgileHandoverRepository.ServerAvailable = false;
            AgileHandoverConfig = {userId:"", userSecret:""};
            console.log( e);
        }
    }
    public async getLinkedAgileUser(itwinId:string) {
        let base =  this.url +"/api/linkedusers?linkedId="+itwinId;
        try {
            let response = await fetch(base, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                "X-User-Id": AgileHandoverSeedConfig.userId, "X-Auth-Token":AgileHandoverSeedConfig.userSecret
                }
            });
            let resp = await response.json();
            if (resp.data) {
                AgileHandoverConfig.userId = resp.data.userId;
                AgileHandoverConfig.userSecret = resp.data.authToken;
            } else {
                AgileHandoverConfig.userId = "";
                AgileHandoverConfig.userSecret ="";
            }
            return AgileHandoverConfig;
        } catch (e) {
            AgileHandoverRepository.ServerAvailable = false;
            AgileHandoverConfig = {userId:"", userSecret:""};
            console.log( e);
        }
    }
    public async setLinkedAgileUser(itwinId:string, secret : any) {
        let base =  this.url +"/api/linkedusers?linkedId="+itwinId;
        try {
            let response = await fetch(base, {
                method: 'POST',
                body: JSON.stringify(secret),
                headers: {
                'Content-Type': 'application/json',
                "X-User-Id": AgileHandoverSeedConfig.userId, "X-Auth-Token":AgileHandoverSeedConfig.userSecret

                }
            });
            let resp = await response.json();           
            if (resp.data) {
                AgileHandoverConfig.userId = resp.data.userId;
                AgileHandoverConfig.userSecret = resp.data.authToken;
            } else {
                AgileHandoverConfig.userId = "";
                AgileHandoverConfig.userSecret ="";
            }
        } catch (e) {
            AgileHandoverRepository.ServerAvailable = false;
            console.log( e);
        }
    }
    public async getAll() {
        let base =  this.url +"/api/packages?fields=iTwinContextId,currentStatus,extractionTemplate";
        try {
            let response = await fetch(base, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                "X-User-Id": AgileHandoverConfig.userId, "X-Auth-Token":AgileHandoverConfig.userSecret
                }
            });
            const j = await response.json();
            console.log(j);
            if (j && j.data)
                return j.data.filter((i:any)=>i.parent!="#");
        } catch (e) {
            AgileHandoverRepository.ServerAvailable = false;
            console.log( e);
        }
    }
    public async getRepositoryForProject(itwinProjectId:string)  {
        let base =  this.url +"/api/packages?iTwinContextId=" + itwinProjectId + "&fields=iTwinContextId,currentStatus,extractionTemplate";
        
        let response = await fetch(base, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            "X-User-Id": AgileHandoverConfig.userId, "X-Auth-Token":AgileHandoverConfig.userSecret
            }
        });
        const j = await response.json();
        console.log(j);
        if (j && j.data)
            return j.data[0];
    }
    public async getPackage (packageId:string) {
        let base =  this.url +"/api/packages/"+packageId;
        try {
            let response = await fetch(base, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                "X-User-Id": AgileHandoverConfig.userId, "X-Auth-Token":AgileHandoverConfig.userSecret
                }
            });
            const j = await response.json();
            //console.log(j);
            if (j)
                return j.data;
        } catch(exception) {
            return null;
        }
    }
    
}
export class AgileHandoverAsset {
    private url:string;
    private packageId : string;
    public repository: any;
    static Init = async (url:string, itwinProjectId: string) =>{
        let client= new AgileHandoverRepository(url);
        let rep : any = await client.getRepositoryForProject(itwinProjectId);
        if (rep )
            return new AgileHandoverAsset(url, rep.id);
    }
    constructor(url:string, packageId:string) {
        this.url = url;
        this.packageId = packageId;
        
    }
    public get AgileHandoverRepositoryId() {
        return this.packageId;
    }
    createAsset (asset:any) {
        let base =  this.url +"/api/packages/"+this.packageId+"/assets/AgileHandoverIModelExtractor";
        

        let response = fetch(base, {
            method: 'POST',
            body: JSON.stringify(asset), // string or object
            headers: {
            'Content-Type': 'application/json',
            "X-User-Id": AgileHandoverConfig.userId, "X-Auth-Token":AgileHandoverConfig.userSecret
            }
        }).then(res => res.json())
        .then(json => console.log(json));
    
    }
    
    async report (name:string) {
        let base =  this.url +"/api/packages/"+this.packageId+"/reports/"+name;
        
        let response = await fetch(base, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            "X-User-Id": AgileHandoverConfig.userId, "X-Auth-Token":AgileHandoverConfig.userSecret
            }
        });
        const j = await response.json();
        //console.log(j);
        if (j)
            return j.data;
    }
    
    async getReports () {
        let base =  this.url +"/api/packages/"+this.packageId+"/reports/";
        
        let response = await fetch(base, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            "X-User-Id": AgileHandoverConfig.userId, "X-Auth-Token":AgileHandoverConfig.userSecret
            }
        });
        const j = await response.json();
        //console.log(j);
        if (j)
            return j.data;
    }
    async getTables () {
        let base =  this.url +"/api/packages/"+this.packageId+"/tables";
        
        let response = await fetch(base, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            "X-User-Id": AgileHandoverConfig.userId, "X-Auth-Token":AgileHandoverConfig.userSecret
            }
        });
        const j = await response.json();
        //console.log(j);
        if (j)
            return j.data;
    }
    async link (contextId:string) {
        let base =  this.url +"/api/packages/"+this.packageId+"/attributes";
        
        let response = await fetch(base, {
            method: 'POST',
            body:JSON.stringify({iTwinContextId:contextId}),
            headers: {
            'Content-Type': 'application/json',
            "X-User-Id": AgileHandoverConfig.userId, "X-Auth-Token":AgileHandoverConfig.userSecret
            }
        });
        const j = await response.json();
        //console.log(j);
        if (j)
            return j.data;
    }
    async saveExtractionConfig (config:any) {
        let base =  this.url +"/api/packages/"+this.packageId+"/attributes";
        
        let response = await fetch(base, {
            method: 'POST',
            body:JSON.stringify({extractionTemplate:config}),
            headers: {
            'Content-Type': 'application/json',
            "X-User-Id": AgileHandoverConfig.userId, "X-Auth-Token":AgileHandoverConfig.userSecret
            }
        });
        const j = await response.json();
        //console.log(j);
        if (j)
            return j.data;
    }
    async getPlans () {
        let base =  this.url +"/api/packages/"+this.packageId+"/plans";
        
        let response = await fetch(base, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            "X-User-Id": AgileHandoverConfig.userId, "X-Auth-Token":AgileHandoverConfig.userSecret
            }
        });
        const j = await response.json();
        //console.log(j);
        if (j)
            return j.data;
    }
    async getTypes (filter:any) {
        let base =  this.url +"/api/packages/"+this.packageId+"/types/component";
        
        let response = await fetch(base, {
            method: 'POST',
            body:JSON.stringify(filter),
            headers: {
            'Content-Type': 'application/json',
            "X-User-Id": AgileHandoverConfig.userId, "X-Auth-Token":AgileHandoverConfig.userSecret
            }
        });
        const j = await response.json();
        //console.log(j);
        if (j)
            return j.data;
    }
    async getAssetsByType (typeName:string) {
        let base =  this.url +"/api/packages/"+this.packageId+"/search";
        
        let response = await fetch(base, {
            method: 'POST',
            body:JSON.stringify({field: "typename",text:typeName}),
            headers: {
            'Content-Type': 'application/json',
            "X-User-Id": AgileHandoverConfig.userId, "X-Auth-Token":AgileHandoverConfig.userSecret
            }
        });
        const j = await response.json();
        //console.log(j);
        if (j)
            return j.data;
    }
    async getAssetByIModelId (id:string) {
        let base =  this.url +"/api/packages/"+this.packageId+"/assets?field=AgileHandoverIModelExtractor.IModelElementId&value="+id;
        
        let response = await fetch(base, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            "X-User-Id": AgileHandoverConfig.userId, "X-Auth-Token":AgileHandoverConfig.userSecret
            }
        });
        const j = await response.json();
        //console.log(j);
        if (j)
            return j.data;
    }
    async getAssetById (id:string) {
        let base =  this.url +"/api/assets/"+id+"/ownerview";
        
        let response = await fetch(base, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            "X-User-Id": AgileHandoverConfig.userId, "X-Auth-Token":AgileHandoverConfig.userSecret
            }
        });
        const j = await response.json();
        //console.log(j);
        if (j)
            return j.data;
    }
    async getAssetDocsById (id:string) {
        let base =  this.url +"/api/assets/"+id+"/docs?includetype=true";
        
        let response = await fetch(base, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/json',
            "X-User-Id": AgileHandoverConfig.userId, "X-Auth-Token":AgileHandoverConfig.userSecret
            }
        });
        const j = await response.json();
        //console.log(j);
        if (j)
            return j.data;
    }
    async processProject (config:any) {
        let base =  this.url +"/api/packages/"+this.packageId+"/automation/IModelExtraction";
        
        let response = await fetch(base, {
            method: 'POST',
            body:JSON.stringify({"imodel":config}),
            headers: {
            'Content-Type': 'application/json',
            "X-User-Id": AgileHandoverConfig.userId, "X-Auth-Token":AgileHandoverConfig.userSecret
            }
        });
        const j = await response.json();
        //console.log(j);
        if (j)
            return j.data;
    }
    public download (url:string) {
        let base =  url;
        try {
            let response = fetch(base, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/octet-stream',
                "X-User-Id": AgileHandoverConfig.userId, "X-Auth-Token":AgileHandoverConfig.userSecret
                }
            }).then( res => res.blob() )
            .then( blob => {
              //var file = window.URL.createObjectURL(blob);
              //window.open(file);
              var a : any = document.createElement("a");
                a.href = URL.createObjectURL(blob);
                a.setAttribute("download", "ownerview.xlsx");
                a.click();
                a.remove();
            });
            
        } catch(exception) {
            return null;
        }
    }
    async test () {
        let base =  this.url +"/api/packages/"+this.packageId+"/automation/ProcessPID";
        
        let response = await fetch(base, {
            method: 'POST',
            body:JSON.stringify({"pids":[{name: "name1.dwg", link:"https://agilehandovercom-my.sharepoint.com/:u:/g/personal/hong_gao_agilehandover_com/EVP5LtUYDH9LiHab9yw-9P8BNiXD5PTomSWDFEOh9SYi0w?e=JuFd1U&download=1"}, 
            {name: "name2.dwg", link:"https://agilehandovercom-my.sharepoint.com/:u:/g/personal/hong_gao_agilehandover_com/EVP5LtUYDH9LiHab9yw-9P8BNiXD5PTomSWDFEOh9SYi0w?e=JuFd1U&download=1"},
            {name:"name3.js",link:"https://agilehandovercom-my.sharepoint.com/:u:/g/personal/hong_gao_agilehandover_com/EVP5LtUYDH9LiHab9yw-9P8BNiXD5PTomSWDFEOh9SYi0w?e=JuFd1U&download=1"}]}),
            headers: {
            'Content-Type': 'application/json',
            "X-User-Id": AgileHandoverConfig.userId, "X-Auth-Token":AgileHandoverConfig.userSecret
            }
        });
        const j = await response.json();
        //console.log(j);
        if (j)
            return j.data;
    }
}
