import { PrimitiveValue, PropertyDescription, PropertyDescriptionHelper, PropertyRecord, PropertyValue, PropertyValueFormat, StandardTypeNames } from "@bentley/ui-abstract";
import { PropertyCategory, PropertyDataChangeEvent, PropertyGrid, SimplePropertyDataProvider } from "@bentley/ui-components";
import { Button, ButtonType, ExpandableBlock, ExpandableBlockProps, ExpandableList, LabeledSelect, Spinner, SpinnerSize } from "@bentley/ui-core";
import { FileUploadTemplate, Table } from "@itwin/itwinui-react";
import { type } from "os";
import React, { useEffect } from "react";
import { FunctionComponent, useState } from "react";
import { AgileHandoverServer } from "./AgileRepository";
import { AgileDataProvider } from "./Handover";
import { IModelApp,  MarginPercent,  Viewport } from "@bentley/imodeljs-frontend";
import { useActiveIModelConnection } from "@bentley/ui-framework";
import { Presentation, SelectionChangeEventArgs } from "@bentley/presentation-frontend";
import { Value } from "@bentley/presentation-common";

/** Sample component using ExpandableBlock with an expanded state  */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const SampleExpandableBlock: React.FC<ExpandableBlockProps> = (props: ExpandableBlockProps) => {
    const [expanded, setExpanded] = React.useState(true);
  
    // Inverts the expandable block's current state
    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      setExpanded(!expanded);
  
      props.onClick && props.onClick(event);
    };
  
    return (
      <ExpandableBlock {...props} isExpanded={expanded} onClick={handleClick} />
    );
  };


  const Collapse = ({ collapsed, children }:{collapsed:any, children:any}) => {
    const [isCollapsed, setIsCollapsed] = React.useState(collapsed);
  
    return (
      <>
        <button
          className="collapse-button"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {isCollapsed ? 'Show' : 'Hide'} content
        </button>
        <div
          className={`collapse-content ${isCollapsed ? 'collapsed' : 'expanded'}`}
          aria-expanded={isCollapsed}
        >
          {children}
        </div>
      </>
    );
  };
const getFieldValue = (asset:any, fldName:string) => {
    if (!asset)
      return null;
    if (asset[fldName])
      return asset[fldName];
    for (let k in asset) {
      if (asset[k] && typeof asset[k]=="object") {
        let v:any = getFieldValue(asset[k], fldName);
        if (v)
          return v;
      }
    }
    return null;
}
export interface OwnerSpecComponentProps {
    provider?:any
  }

export const OwnerSpecComponent = (props :OwnerSpecComponentProps ) => { 
        const provider = props.provider;
        const plan =props.provider.plan;
        const emptyProperties = new SimplePropertyDataProvider();
        const [currentOwnerType, setCurrentOwnerType] = useState("");
        const [types, settypes] = useState(plan.Templates.filter(((i:any)=>i.Properties&&i.Properties.length>0)).map((t:any)=>t.Name));
        const [template, setTemplate] = useState(types[0]);
        const [assettypes, setAssetTypes] = useState([]);
        const [assettype, setAssetType] = useState();
        const [assets, setAssets] = useState([]);
        const [assetName, setAssetName] = useState();
        const [assetProperties, setAssetProperties] = useState(emptyProperties);
        const [assetDocs, setAssetDocs] = useState([]);
        const [downloadLinks, setDownloadLinks] = useState([]);
        const newAssetSelected = function (asset:any) {
          if (asset && asset.length >=1 && getFieldValue(asset[0].ownerview,"TypeName")){
            provider.getAssetsByType(getFieldValue(asset[0].ownerview,"TypeName"), assetsReady);
            setAssetType(getFieldValue(asset[0].ownerview,"TypeName"));
            setAssetName(getFieldValue(asset[0].ownerview,"Name"));
          }
        }
        const onUnifiedSelectionChanged = React.useCallback((args: SelectionChangeEventArgs) => {
          var elemId = args.imodel.selectionSet.elements.values().next().value;
          if (elemId) 
            provider.getAssetByIModelId(elemId, newAssetSelected);
        }, []);
        React.useEffect(() => {
          // subscribe to unified selection changes
          return Presentation.selection.selectionChange.addListener(onUnifiedSelectionChanged);
        }, [onUnifiedSelectionChanged]);

        let options = false;
        const getViews =() =>{
            if (plan&&plan.plan&&plan.plan.views)
            return plan.plan.views;
            else 
            return ["All Templates"];
        }
        const getLifecyles =() =>{
            if (plan &&  plan.plan)
            return plan.plan.lifecycles;
            else
            return [];
        }
        const getTypes =()=> {
            return plan.Templates.map((t:any)=>t.Name);
        }
        const getPropertyProvider = (tn :string, asset:any) => {
            var temp = plan.Templates.find((t:any)=>t.Name === tn);
            
            var retVal = new SimplePropertyDataProvider();
            
            if (temp && temp.Properties) {
                let categories = temp.Properties.map((p:any)=>p.Category).filter((v :string, i :number, a: [string]) => a.indexOf(v) === i);
                
                for (var c of categories){
                   
                    retVal.addCategory( {name:c, label:c,expand:true});
                }
                for (var p of temp.Properties) {
                    let desc : PropertyDescription={name:p.Name,displayLabel:p.Name, typename:StandardTypeNames.String};
                    let val : PrimitiveValue ={valueFormat: PropertyValueFormat.Primitive, value:asset[p.Name]};
                    
                    retVal.addProperty(new PropertyRecord(val, desc), categories.indexOf(p.Category));
                }
                //retVal.isPropertyEditingEnabled = true;
                retVal.onDataChanged= new PropertyDataChangeEvent();
            }
            return retVal;
        }
        const setTableNames =(names:any) =>{
           names.push("Owners's view");
           names.push("Asset Infomation Compiled by Agile Handover");
           setDownloadLinks(names);
           
        }
        const typesReady = (types:any) => {
          setAssetTypes(types);           
        }
        React.useMemo(()=> provider.getTypes(template?template.Name:"", typesReady),[]);
        React.useMemo(()=> provider.getTables(setTableNames),[]);
        const selectAgileOwnerView = (event:any) => {
            if (event.target.value != "Standard")
            settypes(["AssetTable"]);
            else {
            settypes(plan.Templates.filter(((i:any)=>i.Properties&&i.Properties.length>0)).map((t:any)=>t.Name));
            setCurrentOwnerType(plan.Templates[0].Name);
            setAssets([]);
            setAssetProperties(emptyProperties);
            }
        }
        useEffect(() => { 
          if (assets && assets.length>0){
            var asset:any=assets.find((i:any)=>i.name==assetName);
            if (!asset) {
              asset=assets[0];
              setAssetName(asset.name);
            }
            provider.getAssetById(asset._id, transformAssetForPG);  
            provider.getAssetDocsById(asset._id, transformAssetDocs);
          } else 
          transformAssetForPG(null);
         }, [assets])
         useEffect(() => { 
          if (assettypes && assettypes.length>0){
            var t:any=assettypes[0];
            provider.getAssetsByType(t, assetsReady); 
          } else 
            setAssets([]);
         }, [assettypes])
        const assetsReady = (assets:any) => {
          setAssets(assets);         
        }
        const selectAgileOwnerType = (event:any) => {       
            setCurrentOwnerType (  event.target.value);  
            setTemplate(event.target.value);
            provider.getTypes(event.target.value, typesReady)
        }
        const selectAgileHandoverType = (event:any) => {       
            setAssets([]);
            if (event.target.value && event.target.value!=""){
              provider.getAssetsByType(event.target.value, assetsReady);  
              setAssetType(event.target.value);
            }
            
        }
        const transformAssetForPG = (asset:any) => {       
            let nt=currentOwnerType.split('.').join('_');
            if (currentOwnerType !="" && asset && asset[nt]) {
                for (var k in asset[nt]){
                    if (asset[nt][k] && typeof asset[nt][k] === 'object' ) {
                        asset[nt][k] = JSON.stringify(asset[nt][k])
                    }
                }
                let prgProvider : SimplePropertyDataProvider = getPropertyProvider(currentOwnerType,asset[nt]);
                
                setAssetProperties(prgProvider);
            } else 
            setAssetProperties(new SimplePropertyDataProvider());
            const vp = IModelApp.viewManager.selectedView as Viewport;
            
            let eleId = getFieldValue(asset, "IModelElementId");
            if (eleId) {
              vp.zoomToElements([eleId], { animateFrustumChange: true , marginPercent: new MarginPercent(0.01,0.01,0.25,0.25)});
            }
           
        }
        const transformAssetDocs = (docs:any) => {       
            for (var doc of docs){
                doc.Link= (doc.docLink?"Open":"Link");
                if (!doc.Title)
                    doc.Title=doc.Name;
            }
            setAssetDocs(docs);
            
        }
        const selectAgileHandoverAsset = (event:any) => {
            let assetrec :any = assets.find((a:any)=>{return a.name === event.target.value});       
            if (assetrec) {
               setAssetName(assetrec.name);
                provider.getAssetById(assetrec._id, transformAssetForPG);  
                provider.getAssetDocsById(assetrec._id, transformAssetDocs);
            }
            //setTemplate();
        }
        const downloadOwnerSpec = (url:any) =>{
            provider.download(url);
            return false;
        }
        const columns = React.useMemo(() => [
            {
              Header: 'Header name',
              columns: [
                {
                  id: 'name',
                  Header: 'Name',
                  accessor: 'Title',
                  width: 300,
                },
                {
                  id: 'category',
                  Header: 'Category',
                  accessor: 'Category',
                  maxWidth: 200,
                },
                {
                    id: 'link',
                    Header: 'Link',
                    accessor: 'docLink',
                    maxWidth: 90,
                    Cell: (e:any) => {
                      if (e.value)
                        return <a href={e.value} target="_blank">Open</a>
                      else 
                        return "NA";
                    },
                },
                  
              ],
            },
           ], []);
           const data = [
            { name: 'Name1', description: 'Description1' },
            { name: 'Name2', description: 'Description2' },
            { name: 'Name3', description: 'Description3' },
           ]
           //<LabeledSelect className="uicore-full-width"  options={downloadLinks} />
        return <>
            
        <div>
            
            <table width="100%"> 
            <tbody>
            {(function() {
              if (plan&&(plan.plan&&plan.plan.views&&plan.plan.views.length>0 || plan.Templates&&plan.Templates.length>0 )) 
              return <>
              <tr>
              <td width="30%"><LabeledSelect label="Handover View" className="uicore-full-width"  options={getViews()} onChange={selectAgileOwnerView} /></td>            
              <td><LabeledSelect label="Project Lifecycle" options={getLifecyles()} /></td>
              <td><LabeledSelect label="Supplier Role" className="uicore-full-width"  options={["Vendor","Designer","Contractor","Operator"]}/></td>
              </tr>
              <tr>           
              
              <td colSpan={3}><LabeledSelect label="Ownerview Type" className="uicore-full-width" options={types} onChange={selectAgileOwnerType} /></td>
              </tr>
              <tr>               
                  <td><LabeledSelect label="Handover Type" className="uicore-full-width" options={assettypes} onChange={selectAgileHandoverType} value={assettype} onClick={selectAgileHandoverType} /></td>
                  <td colSpan={2}><LabeledSelect label="Asset" className="uicore-full-width" options={assets.map((a :any)=>a.name)}  value={assetName} onClick={selectAgileHandoverAsset}  /></td>
              </tr>
              </>
            else 
              return <>
               <tr><td  colSpan={3}>No owner's view is defined.</td></tr>
              </>
            })()
          }
            <tr>
                <td colSpan={3}>
                <ExpandableList className="uicore-full-width" singleExpandOnly={true} defaultActiveBlock={0}>
                    {(function() {
                      if (plan&&(plan.plan&&plan.plan.views&&plan.plan.views.length>0|| plan.Templates&&plan.Templates.length>0)) 
                      return <>
                      <SampleExpandableBlock title="Handover Fields" isExpanded={true} onClick={() => { }}>
                          <PropertyGrid className="uicore-full-width" dataProvider={assetProperties }  />
                      </SampleExpandableBlock>
                      <SampleExpandableBlock title="Handover Documents" isExpanded={false} onClick={() => { }}>
                          <Table columns={columns} data={assetDocs} emptyTableContent='No data' /> 
                      </SampleExpandableBlock>
                      <SampleExpandableBlock title="Download and Link" isExpanded={false} onClick={() => { }}>
                          {downloadLinks.map (l=>{
                              let url= AgileHandoverServer+"/lists/"+provider.AgileHandoverRepositoryId +"/"+l;
                              if (l=="Owners's view") {
                                url= AgileHandoverServer+"/download/"+provider.AgileHandoverRepositoryId;
                                return <div key={l}><a href='#' target="_blank" onClick={(e)=>{e.preventDefault();downloadOwnerSpec(url)}}>{l}</a></div>
                              } else if (l=="Asset Infomation Compiled by Agile Handover")
                                url= AgileHandoverServer + "/packages/" + provider.AgileHandoverRepositoryId +"/startup?name="+assetName;
                              return <div key={l}><a href={url} target="_blank">{l}</a></div>
                          })
                          } 
                      </SampleExpandableBlock>
                      </>
                      else 
                      return <>
                        <SampleExpandableBlock title="Download and Link" isExpanded={true} onClick={() => { }}>
                            {downloadLinks.map (l=>{
                                let url= AgileHandoverServer+"/lists/"+provider.AgileHandoverRepositoryId +"/"+l;
                                if (l=="Owners's view") {
                                  url= AgileHandoverServer+"/download/"+provider.AgileHandoverRepositoryId;
                                  return <div key={l}><a href='#'  onClick={(e)=>{e.preventDefault();downloadOwnerSpec(url)}}>{l}</a></div>
                                } else if (l=="Asset Infomation Compiled by Agile Handover")
                                  url= AgileHandoverServer + "/packages/" + provider.AgileHandoverRepositoryId +"/startup?name="+assetName;
                                return <div key={l}><a href={url} target="_blank">{l}</a></div>
                            })
                            } 
                        </SampleExpandableBlock>
                      </>
                    })()}
                    
                </ExpandableList>
                </td>
            </tr>
            </tbody>
            </table>
        </div>
        
        </>;
    
    
};
  