import { AccessToken } from "@bentley/itwin-client";
import { Button, ButtonType, LabeledInput } from "@bentley/ui-core";
import { ButtonGroup } from "@itwin/itwinui-react";
import { navigate } from "@reach/router";
import React from "react";
import { useState } from "react";
import AuthClient from "../../services/auth/AuthClient";
import { AgileHandoverRepository, AgileHandoverServer } from "./AgileRepository";

export const LinkUserRouter = (accessToken:{itwinId:string}) => {
    const [userEmail, setUserEmail] = useState("");
    const [pass, setPass] = useState("");
    const login = () => {
        try {
          let rep: AgileHandoverRepository = new AgileHandoverRepository(AgileHandoverServer);
          let c = accessToken;
          rep.login(accessToken.itwinId, userEmail,pass).then((user)=>{
              if (user?.userId!=""){
                window.location.reload();
              }
          });
          //console.log(c);
          //navigator
        } catch (e){
    
        }
        
        
      };
      const changeEmail =  (event:any) => {
          setUserEmail(event.target.value);
        }
      const changePass =  (event:any) => {
          setPass(event.target.value);
        }
    return <>
    <div className={"idp-scrolling-iac-dialog"}>
      <div className={"idp-content-margins"}>
     
      <LabeledInput label="Agile Handover User Name" onChange={changeEmail} value={userEmail} className="uicore-full-width"></LabeledInput>
      </div>
      <div className={"idp-content-margins"}>
      <LabeledInput label="Agile Handover Password" className="uicore-full-width" value={pass} type="password" onChange={changePass}></LabeledInput>
      </div>
     
      <div className={"idp-content-margins"}>
      <ButtonGroup><Button buttonType={ButtonType.Hollow} onClick={login}>Login</Button>
      </ButtonGroup>
      </div>
    </div>
   </> 
  };