import { BeEvent, Id64Arg } from "@bentley/bentleyjs-core";
import { IModelApp, MarginPercent,  Viewport } from "@bentley/imodeljs-frontend";
import { PropertyRecord } from "@bentley/ui-abstract";
import { AbstractTreeNodeLoaderWithProvider, ColorSwatch, ControlledTree, DelayLoadedTreeNodeItem, ITreeDataProvider, SelectionMode, TreeDataChangesListener, TreeDataProvider, TreeEventHandler, TreeNodeItem, TreeSelectionModificationEventArgs, TreeSelectionReplacementEventArgs, useTreeModelSource, useTreeNodeLoader, useVisibleTreeNodes } from "@bentley/ui-components";
import { HorizontalTabs, LabeledSelect, Spinner, SpinnerSize, useDisposable } from "@bentley/ui-core";
import React from "react";
import { useState } from "react";
import { AgileHandoverAsset, AgileHandoverServer } from "./AgileRepository";
import { AgileDataProvider } from "./Handover";

export class ReportDataProvider implements ITreeDataProvider {
    private report:any = {};
    private agent : any;
    private imodelId:any;
    public loading = true; 
    public onTreeNodeChanged = new BeEvent<TreeDataChangesListener>();
    constructor (agileDataProvider:AgileDataProvider, reportName:string, imodelId:any, treeReady:any) {      
      this.agent = agileDataProvider.Agent;
      this.imodelId=imodelId;
      this.agent.report(reportName).then((report :any) => {this.report=report; 
        this.loading=false; 
        if (Object.keys(this.report).length>0) 
          treeReady("hasData");
        else
          treeReady("noData");
        });
    } 
    
    public async getNodesCount(parent?: TreeNodeItem) {
      if (parent === undefined)
        return Object.keys(this.report).length;
      else {
        let parents = parent.id.split(':');
        if (parents.length ===2 && this.report[parents[1]]) {
            return this.report[parents[1]].data.length;
        }
        if (parents.length ===3 && this.report[parents[1]]) {
            var items = this.report[parents[1]].data.find((ele:any)=>ele._id === parents[2]);
            if (items)
                return items._Items.length;
            
        }
        return 0;
      }
    }
  
    public async getNodes(parent?: TreeNodeItem) {
      var retVal = [];
      if (parent === undefined) {
          
          for (var k in this.report) {
              retVal.push(createNode("0:"+k,k,true));
          }
        
      } else {
        let parents = parent.id.split(':');  
        
        if (parents.length ===2 && this.report[parents[1]]) {
            for (var item of this.report[parents[1]].data)
                retVal.push(createNode(parent.id+":"+(item._id||"Undefined"), item._id + " (" + item.Count +")"||"Undefinded", item.Count>0) );
        }
        if (parents.length ===3 && this.report[parents[1]]) {
            var items = this.report[parents[1]].data.find((ele:any)=>ele._id === parents[2]);
            if (items) {
              let lst = items._Items.sort((i:any,j:any)=>(i.Name<j.Name)?-1:1)
              for (var item of lst) {
                  if (item.IModelId &&( item.IModelId == this.imodelId || item.IModelId.indexOf( this.imodelId)>=0))
                    retVal.push(createNode(parent.id+":"+item._id._str, item.Name, false) );
                  else 
                    retVal.push(createNode(parent.id+":"+item._id._str, item.Name, false, true) );
              }
          }
        }
      }
      return retVal;
    }

    public getDataById(id:string) : any{
      var retVal:any;
      var ids = id.split(":");
      if (ids.length>=2) {
          retVal = this.report[ids[1]];
          if (ids.length>=3) {
            retVal = retVal.data.find((r:any)=>r._id===ids[2]);
            
            if (retVal && ids.length===4) {
              retVal = retVal._Items.find((n:any) => n._id._str === ids[3]);
              
            }
          }

          
      }
      return retVal;
    }
  }
  
  const createNode = (id: string, label: string, hasChildren?: boolean, isExternal?:boolean): DelayLoadedTreeNodeItem => {
    let n:DelayLoadedTreeNodeItem = 
     {
      id,
      label: PropertyRecord.fromString(label),
      hasChildren,
      
    };
    if (isExternal) {
      n.style ={isItalic : isExternal};
      n.style.colorOverrides ={color: 0xaaaaaaaa,colorSelected:0xaaaaaaaa};
    }
    return n;
  };

export interface OwnerSpecReportProps {
    imodelId:any,
    agileProvider:any,
    report: string
  }

export const OwnerSpecReport = (props :OwnerSpecReportProps ) => { 
    //const currentImodelId = props.imodelId;
    const [treeReady, setTreeReady] = useState();
    const dataProvider = React.useMemo(() => new ReportDataProvider(props.agileProvider,props.report,props.imodelId,setTreeReady), []);
    const modelSource = useTreeModelSource(dataProvider);
    const nodeLoader = useTreeNodeLoader(dataProvider, modelSource);
    const eventHandler = useDisposable(React.useCallback(() => new SynchronizedCheckboxSelectionHandler(nodeLoader,props.imodelId), [nodeLoader]));
    const visibleNodes = useVisibleTreeNodes(modelSource);

    if (treeReady) {
        if (treeReady === "hasData")
          return <>
                        
              <ControlledTree
                      nodeLoader={nodeLoader}
                      selectionMode={SelectionMode.Single}
                      treeEvents={eventHandler}
                      visibleNodes={visibleNodes}
              /> 
              
          </>;
        else
          return <>
            <div>No handover reports are configured in the linked Agile Handover Repository.</div>
          </>
      } else {
        return <>
            
            <div><Spinner size={SpinnerSize.Medium} /></div>       
        </>;
      }
    
};
/** Custom even handler that synchronizes selection and checkboxes */
class SynchronizedCheckboxSelectionHandler extends TreeEventHandler {
    private nodeLoader: AbstractTreeNodeLoaderWithProvider<TreeDataProvider>;
    private imodelId :any;
    constructor(nodeLoader: AbstractTreeNodeLoaderWithProvider<TreeDataProvider>, imodelId:any) {
      super({ modelSource: nodeLoader.modelSource, nodeLoader, collapsedChildrenDisposalEnabled: true });
      this.nodeLoader =nodeLoader;
      this.imodelId = imodelId;
    }
  
    /** Selects or deselects nodes until event is handled, handler is disposed selection replaced event occurs.  */
    public onSelectionModified(event: TreeSelectionModificationEventArgs) {
      // call base selection handling
      const baseSubscription = super.onSelectionModified(event);
      
      // subscribe to selection modifications to get selected and deselected items and do some additional
      // work with them
      const subscription = event.modifications.subscribe({
        next: ({ selectedNodeItems, deselectedNodeItems }) => {
          // here goes code that should be invoked when selection in tree is modified. It could
          // be some additional modifications to tree model or some code that depends on tree selection
  
          // in case some selected/deselected nodes are not loaded yet this callback will be invoked several times
          // with new 'selectedNodeItems' and 'deselectedNodeItems' when they are loaded
          
        },
      });
  
      // stop checkboxes handling when base selection handling is stopped
      baseSubscription?.add(subscription);
      return baseSubscription;
    }
  
    /** Replaces currently selected nodes until event is handled, handler is disposed or another selection replaced event occurs. */
    public onSelectionReplaced(event: TreeSelectionReplacementEventArgs) {
      // call base selection handling
      const baseSubscription = super.onSelectionReplaced(event);
  
      let firstEmission = true;
      var provider =this.nodeLoader.dataProvider as ReportDataProvider;
          // modify nodes in tree model by checking/unchecking selected/deselected nodes' checkboxes
      async function zoomToSelectedElements(vp: Viewport,elems:Id64Arg) {
          await vp.zoomToElements(elems, { animateFrustumChange: true , marginPercent: new MarginPercent(0.01,0.01,0.25,0.25)});
      }    
      // subscribe to selection replacements to get selected node items and do some additional work
      // with them
      const subscription = event.replacements.subscribe({
        next: ({ selectedNodeItems }) => {
          // here goes code that should be invoked when selection in tree is replaced. It could be some additional
          // modifications to tree model or some code that depends on tree selection
  
          // in case some selected nodes are not loaded yet, this callback will be invoked several times
          // with new 'selectedNodeItems' when they are loaded
  
          // modify nodes in tree model by checking selected nodes' checkboxes
          this.modelSource.modifyModel((model) => {
            // on first emission turn off all checkboxes
            if (firstEmission) {
              
              firstEmission = false;
            }
            //alert (provider.getDataById(selectedNodeItems[0].id).IModelElementId);
            const vp = IModelApp.viewManager.selectedView as Viewport;
            var reprotData = provider.getDataById(selectedNodeItems[0].id);
            if (reprotData.IModelId && (this.imodelId == reprotData.IModelId || reprotData.IModelId.indexOf(this.imodelId)>=0)) {
              if (!Array.isArray(reprotData.IModelId))
                zoomToSelectedElements(vp, reprotData.IModelElementId);
              else {
                let modelIndex= reprotData.IModelId.indexOf(this.imodelId);
                if (modelIndex<reprotData.IModelElementId.length)
                zoomToSelectedElements(vp, reprotData.IModelElementId[modelIndex]);
              }

            }
          });
        },
      });
      // stop checkboxes handling when base selection handling is stopped
      baseSubscription?.add(subscription);
      return baseSubscription;
    }
    
  }